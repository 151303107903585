.c-navbar {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  padding: $spacing * 2;
  transition: 0.1s ease;
  z-index: 999;
  box-sizing: border-box;
  background: linear-gradient(rgba($gray-1,0.8), rgba($gray-1,0));

  @media only screen and (min-width: $breakpoint) {
    padding: $spacing * 3;
  }

  &--neon-2 {
    background: linear-gradient(rgba($neon-2,0.8), rgba($neon-2,0));
    .c-navbar__logo {
      fill: $gray-3;
    }
    .c-navbar__button {
      color: $gray-3;
    }
  }

  &--gray-3 {
    background: linear-gradient(rgba($gray-3,1), rgba($gray-3,0));
    .c-navbar__logo {
      fill: $gray-1;
    }
    .c-navbar__button {
      color: $gray-1;
    }
  }

  &--blend {
    mix-blend-mode: difference;
    .c-navbar__logo {
      fill: $gray-3;
    }
    .c-navbar__button {
      color: $gray-3;
    }
  }
}

.c-navbar__logo {
  width: $spacing * 5;
  height: $spacing * 5;
  min-width: $spacing * 5;
  min-height: $spacing * 5;
  fill: $neon-1;
  margin-right: $spacing;
  transition: 0.1s ease;

  &:focus,
  &:hover {
    fill: $neon-3;
  }

  @media only screen and (min-width: $breakpoint) {
    width: $spacing * 7;
    height: $spacing * 7;
    min-width: $spacing * 7;
    min-height: $spacing * 7;
    margin-right: $spacing * 3;
  }
}

.c-navbar__menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;

  @media only screen and (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.c-navbar__button {
  font-family: $font-display;
  font-size: $ts-fs-6;
  line-height: $ts-fs-6;
  color: $neon-1;
  text-decoration: none;
  margin-right: $spacing * 2;
  margin-bottom: $spacing;
  transition: 0.1s ease;

  &:focus,
  &:hover {
    color: $neon-3;
    border-bottom: solid 1px $neon-3;
  }

  @media only screen and (min-width: $breakpoint) {
    font-size: $ts-fs-5;
    line-height: $ts-fs-5;
    margin-right: $spacing * 3;
  }
}

.c-navbar__icon {
  width: $spacing * 5;
  height: $spacing * 5;
  min-width: $spacing * 5;
  min-height: $spacing * 5;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    transform: rotateZ(0);
    transition: transform 0.6s ease, fill 0.2s ease;
  }
  
  @media only screen and (min-width: $breakpoint) {
    width: $spacing * 7;
    height: $spacing * 7;
    min-width: $spacing * 7;
    min-height: $spacing * 7;
  }

  &:focus,
  &:hover {
    svg {
      fill: $neon-3;
      transform: rotateX(-360deg);
    }
  }
}

.c-navbar__icon--animated {
  position: relative;
  border: solid 1px $neon-1;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;

  svg {
    position: absolute;
    right: 0;
    top: 0;
    animation: rotateY 6s linear infinite;
    transform: rotateY(90deg);
    fill: $neon-1;

    &:nth-child(2) {
      animation-delay: 3s;
    }
  }
}

@keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  25% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  75% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}