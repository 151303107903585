.c-shape-motion-crown {
  display: grid;
  will-change: transform;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  &:nth-child(1) {
    transform-origin: top;
    animation: motion-crown-1 2s ease infinite;
  }
  &:nth-child(2) {
    margin-top: -100%;
    transform-origin: bottom;
    animation: motion-crown-2 2s ease infinite;
  }
}

@keyframes motion-crown-1 {
  0% { transform: scaleY(0); }
  50% { transform: scaleY(1); }
  100% { transform: scaleY(1); }
}

@keyframes motion-crown-2 {
  0% { transform: scaleY(1); }
  50% { transform: scaleY(0); }
  100% { transform: scaleY(0); }
}

.c-shape-motion-target {
  display: grid;
  will-change: transform;
  width: 100%;
  overflow: hidden;
  transition: 0.2s ease;
  transform: scale(1);
  animation: motion-target 8s ease infinite;
}

@keyframes motion-target {
  0% { background-color: $gray-2; fill: $gray-3; }
  50% { background-color: $gray-3; fill: $gray-2; }
  100% { background-color: $gray-2; fill: $gray-3; }
}

.c-shape-motion-wheel {
  display: grid;
  will-change: transform;
  transition: 0.1s ease;
}

.c-shape-motion-accordion {
  display: grid;
}