/* ----------------
COLORS
---------------- */

.u-c-neon-1 { color: $neon-1 !important; }
.u-c-neon-2 { color: $neon-2 !important; }
.u-c-neon-3 { color: $neon-3 !important; }
.u-c-gray-1 { color: $gray-1 !important; }
.u-c-gray-2 { color: $gray-2 !important; }
.u-c-gray-3 { color: $gray-3 !important; }

.u-bc-neon-1 { background-color: $neon-1 !important; }
.u-bc-neon-2 { background-color: $neon-2 !important; }
.u-bc-neon-3 { background-color: $neon-3 !important; }
.u-bc-gray-1 { background-color: $gray-1 !important; }
.u-bc-gray-2 { background-color: $gray-2 !important; }
.u-bc-gray-3 { background-color: $gray-3 !important; }

.u-cf-neon-1 { fill: $neon-1 !important; }
.u-cf-neon-2 { fill: $neon-2 !important; }
.u-cf-neon-3 { fill: $neon-3 !important; }
.u-cf-gray-1 { fill: $gray-1 !important; }
.u-cf-gray-2 { fill: $gray-2 !important; }
.u-cf-gray-3 { fill: $gray-3 !important; }


/* ----------------
SPACING
---------------- */

.u-m-0 { margin: 0 !important; }

@for $i from 0 through 16 {
  .u-m-#{$i} { margin: $spacing * $i !important; }
  .u-mt-#{$i} { margin-top: $spacing * $i !important; }
  .u-mb-#{$i} { margin-bottom: $spacing * $i !important; }
  .u-ml-#{$i} { margin-left: $spacing * $i !important; }
  .u-mr-#{$i} { margin-right: $spacing * $i !important; }

  .u-p-#{$i} { padding: $spacing * $i !important; }
  .u-pt-#{$i} { padding-top: $spacing * $i !important; }
  .u-pb-#{$i} { padding-bottom: $spacing * $i !important; }
  .u-pl-#{$i} { padding-left: $spacing * $i !important; }
  .u-pr-#{$i} { padding-right: $spacing * $i !important; }
}

@for $i from 0 through 16 {
  @media only screen and (min-width: $breakpoint) {
    .u-m-#{$i}-md { margin: $spacing * $i !important; }
    .u-mt-#{$i}-md { margin-top: $spacing * $i !important; }
    .u-mb-#{$i}-md { margin-bottom: $spacing * $i !important; }
    .u-ml-#{$i}-md { margin-left: $spacing * $i !important; }
    .u-mr-#{$i}-md { margin-right: $spacing * $i !important; }
  
    .u-p-#{$i}-md { padding: $spacing * $i !important; }
    .u-pt-#{$i}-md { padding-top: $spacing * $i !important; }
    .u-pb-#{$i}-md { padding-bottom: $spacing * $i !important; }
    .u-pl-#{$i}-md { padding-left: $spacing * $i !important; }
    .u-pr-#{$i}-md { padding-right: $spacing * $i !important; }
  }
}

/* ----------------
FONTS
---------------- */

.u-ft-display {
  font-family: $font-display;
  letter-spacing: -0.05em;
}

.u-ft-sans {
  font-family: $font-sans;
}

.u-ft-serif {
  font-family: $font-serif;
  letter-spacing: -0.05em;
}

.u-fw-500 { font-weight: 500 !important; }
.u-fw-700 { font-weight: 700 !important; }

.u-align-r {
  text-align: right;
}

/* ----------------
TYPESCALE
---------------- */

.u-ts-1 {
  font-size: $ts-fs-1 !important;
  line-height: $ts-lh-1 !important;
  @media only screen and (min-width: $breakpoint) {
    font-size: $ts-fs-1-md !important;
    line-height: $ts-lh-1-md !important;
  }
}

.u-ts-2 {
  font-size: $ts-fs-2 !important;
  line-height: $ts-lh-2 !important;
  @media only screen and (min-width: $breakpoint) {
    font-size: $ts-fs-2-md !important;
    line-height: $ts-lh-2-md !important;
  }
}

.u-ts-3 {
  font-size: $ts-fs-3 !important;
  line-height: $ts-lh-3 !important;
  @media only screen and (min-width: $breakpoint) {
    font-size: $ts-fs-3-md !important;
    line-height: $ts-lh-3-md !important;
  }
}

.u-ts-4 {
  font-size: $ts-fs-4 !important;
  line-height: $ts-lh-4 !important;
}

.u-ts-5 {
  font-size: $ts-fs-5 !important;
  line-height: $ts-lh-5 !important;
}

.u-ts-6 {
  font-size: $ts-fs-6 !important;
  line-height: $ts-lh-6 !important;
}

/* ----------------
FLEX
---------------- */

.u-flex-0_9 { flex: 0.9 !important }
.u-flex-1 { flex: 1 !important }
.u-flex-2 { flex: 2 !important }

/* ----------------
POSITION
---------------- */

.u-pos-relative { position: relative; }
.u-pos-absolute { position: absolute; }

.u-z-index-2 { z-index: 2; }
.u-inline-block { display: inline-block; }

/* ----------------
VISIBILITY
---------------- */

.u-show-md {
  display: none;
  @media only screen and (min-width: $breakpoint) {
    display: inherit;
  }
}

.u-hidden-md {
  display: inherit;
  @media only screen and (min-width: $breakpoint) {
    display: none;
  }
}

.u-hidden {
  display: none;
}