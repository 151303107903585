.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  &--nowrap {
    flex-wrap: nowrap;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: start;
}

@for $i from 1 through 12  {
  .col-#{$i} {
    grid-column: span $i;
  }
}

@for $i from 1 through 12 {
  .col-md-#{$i} {
    @media (min-width: $breakpoint) {
      grid-column: span $i;
    }
  }
}

