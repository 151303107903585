.c-post-header {
  position: relative;
  background-color: $neon-2;
  color: $gray-3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: $spacing * 9;
  padding-left: $spacing * 2;
  padding-right: $spacing * 2;

  @media only screen and (min-width: $breakpoint) {
    margin-top: $spacing * 13;
    margin-left: $spacing * 6.5;
    padding-left: $spacing * 6.5;
    padding-right: $spacing * 3;
  }
}

.c-post-header__logo {
  //mix-blend-mode: plus-lighter;
  position: absolute;
  fill: $gray-1;
  width: $spacing * 21.25;
  right: $spacing * 2;
  bottom: - $spacing * 2.5;
  z-index: 3;

  svg {
    transition: 0.1s ease;
  }

  &:focus,
  &:hover {
    mix-blend-mode: normal;
    fill: $neon-3;
    svg {
      transform: rotateX(180deg);
    }
  }

  @media only screen and (min-width: $breakpoint) {
    transform: rotate(-90deg);
    top: $spacing * 16;
    left: - $spacing * 14.75;
    right: auto;
    height: $spacing * 7;
    width: $spacing * 29.5;
  }

  @media only screen and (min-width: 1000px) {
    position: fixed;
    top: $spacing * 29;
    left: - $spacing * 8.25;
  }
}

.c-post-header__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  opacity: 0.5;
}

.c-post {
  max-width: $spacing * 100;
  margin: 0 auto;
  padding: $spacing * 6 $spacing * 2;
  font-weight: 400;

  @media only screen and (min-width: $breakpoint) {
    padding: $spacing * 8 $spacing * 3;
  }

  p, ul, ol {
    letter-spacing: 0;
    font-family: $font-sans;
    font-size: $ts-fs-5;
    line-height: $ts-lh-5;
  }

  h3 {
    font-size: $ts-fs-4;
    line-height: $ts-lh-4;
  }

  a:not(.c-button) {
    color: $gray-1;
    font-weight: 500;
    &:focus,
    &:hover {
      color: $neon-3;
    }
  }

  ul, ol {
    padding-left: $spacing * 2.5;
  }

  hr {
    border-top: none;
    border-bottom: solid 1px;
  }
}

.c-post__img {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
    border-radius: $spacing * 2 0 $spacing * 2 0;
  }

  figcaption {
    margin-top: $spacing;
    margin-bottom: $spacing;
    font-family: $font-sans;
    font-size: $ts-fs-6;
    line-height: $ts-lh-6;
  }
}

.c-post__video {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border-radius: $spacing * 2 $spacing * 2 $spacing * 2 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}