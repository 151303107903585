.c-image-cover {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  opacity: 0.2;
}

.c-image-label-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.c-image-label-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: $spacing * 2;
  border-left: solid 1px $gray-1;
  margin-bottom: $spacing;
}

.c-image-svg {
  svg {
    height: $spacing * 9;
  }
}

.c-image-svg-sm {
  svg {
    height: $spacing * 7;
  }
}

.c-image-lg {
  height: $spacing * 22;
}