/* ----------------
IMPORT FONTS
---------------- */

// Disket Mono

@font-face {
  font-family: Disket-Mono;
  src: url("../fonts/Disket-Mono-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Disket-Mono;
  src: url("../fonts/Disket-Mono-Regular.ttf");
  font-weight: 500;
  font-style: normal;
}

// Space Grotesk

@font-face {
  font-family: Space-Grotesk;
  src: url("../fonts/SpaceGrotesk-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Space-Grotesk;
  src: url("../fonts/SpaceGrotesk-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

// Junicode

@font-face {
  font-family: Junicode;
  src: url("../fonts/JunicodeTwoBeta-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Junicode;
  src: url("../fonts/JunicodeTwoBeta-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Junicode;
  src: url("../fonts/JunicodeTwoBeta-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Junicode;
  src: url("../fonts/JunicodeTwoBeta-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

/* ----------------
FONT DEFAULTS
---------------- */

body {
	font-family: 'Junicode', 'Times New Roman', serif;
	-moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $gray-3;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Disket-Mono', Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
}

p {
  margin: 0;
  letter-spacing: -0.5px;
}
